/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, TitleMain, Subtitle, Text, Title, Fullmap, FullmapWrap, FullmapCover, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kohútovi"}>
        <Column className="css-1rkr4w0 css-8tguze --style2 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37249/5ec7854b1a014e19bd6696a50f256ec2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37249/5ec7854b1a014e19bd6696a50f256ec2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37249/5ec7854b1a014e19bd6696a50f256ec2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37249/5ec7854b1a014e19bd6696a50f256ec2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37249/5ec7854b1a014e19bd6696a50f256ec2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37249/5ec7854b1a014e19bd6696a50f256ec2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37249/5ec7854b1a014e19bd6696a50f256ec2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37249/5ec7854b1a014e19bd6696a50f256ec2_s=3000x_.jpg);
    }
  
background-position: 50% 69%;
    `}>
          
          <ColumnWrap className="column__flex --left el--1 pb--80 flex--center" style={{"marginBottom":25}} columns={"1"}>
            
            <ColumnWrapper className="--style1 --right mt--0 pl--20" style={{"maxWidth":1000,"marginTop":null,"paddingTop":60,"paddingLeft":null,"marginBottom":0,"paddingBottom":0}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={null} svg={true} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":290,"marginTop":0,"paddingTop":0,"paddingLeft":null,"marginBottom":0,"paddingBottom":0}} srcSet={null} content={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\r\n\t viewBox=\"0 0 95.1 80\" style=\"enable-background:new 0 0 95.1 80;\" xml:space=\"preserve\">\r\n<style type=\"text/css\">\r\n\t.st0{fill:#FFFFFF;}\r\n</style>\r\n<path class=\"st0\" d=\"M64.4,25.5c-0.6,0-1.3,0-1.9,0.1C57.3,15,46.6,8.2,34.7,8.2c-17,0-30.9,13.9-30.9,30.9S17.7,70,34.7,70\r\n\tc3.3,0,6.5-0.5,9.6-1.5c5,6,12.3,9.4,20.1,9.4c14.4,0,26.2-11.8,26.2-26.2S78.8,25.5,64.4,25.5z M6.3,39c0-15.7,12.7-28.4,28.4-28.4\r\n\tc10.6,0,20.3,5.9,25.2,15.2c-2.2,0.4-4.4,1.1-6.4,2c-3.9-6.6-11-10.7-18.8-10.7C22.6,17.1,12.8,27,12.8,39s9.8,22,21.9,22\r\n\ts21.9-9.9,21.9-22c0-0.5,0-1-0.1-1.5c1.9-1.1,4-1.7,6.3-1.9c0.1,1.1,0.2,2.3,0.2,3.4c0,15.7-12.7,28.4-28.4,28.4S6.3,54.7,6.3,39z\r\n\t M51.3,29c-7.8,4.5-13.1,13-13.1,22.7c0,2.1,0.3,4.3,0.8,6.3c-1.4,0.3-2.8,0.5-4.2,0.5c-10.7,0-19.4-8.7-19.4-19.4\r\n\ts8.7-19.4,19.4-19.4C41.6,19.6,47.8,23.2,51.3,29z M48.3,51.7c0-5,2.3-9.5,5.9-12.4c-0.1,5.3-2.3,10.2-5.8,13.7\r\n\tC48.3,52.5,48.3,52.1,48.3,51.7z M65.4,35.6c8.4,0.5,15.1,7.5,15.1,16.1c0,8.9-7.2,16.1-16.1,16.1c-4.1,0-7.9-1.5-10.9-4.2\r\n\tC60.9,57.9,65.6,49,65.6,39C65.6,37.9,65.6,36.7,65.4,35.6z M64.4,75.4c-6.8,0-13.1-2.8-17.6-7.9c1.6-0.7,3.2-1.5,4.7-2.5\r\n\tc3.5,3.4,8.1,5.3,13,5.3c10.3,0,18.6-8.4,18.6-18.6S74.7,33,64.4,33s-18.6,8.4-18.6,18.6c0,1,0.1,2.1,0.3,3.1c-1.4,1-3,1.9-4.7,2.5\r\n\tc-0.4-1.8-0.7-3.7-0.7-5.6c0-13,10.6-23.6,23.7-23.6s23.7,10.6,23.7,23.7S77.5,75.4,64.4,75.4z\"/>\r\n</svg>\r\n"}>
              </Image>

              <TitleMain className="title-box fs--48 w--900 swpf--uppercase ls--12 lh--1" style={{"maxWidth":365,"marginTop":10,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"<span style=\"color: white\">markéta <br>&amp; pavel</span>"}>
              </TitleMain>

              <Subtitle className="subtitle-box ff--1 fs--36 w--600 lh--14" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">15. června 2024</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"informace"} style={{"marginTop":0,"paddingTop":40,"paddingBottom":54,"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":0}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center ff--1 fs--48" style={{"maxWidth":450,"marginTop":0,"marginBottom":0}} content={"<span style=\"color: var(--color-supplementary)\">Svatba&nbsp;</span>"}>
              </Text>

              <Text className="text-box ff--1 fs--18" style={{"maxWidth":837,"marginTop":2}} content={"<span style=\"color: var(--black);\">Zveme Vás na naši svatbu, která se bude konat <span style=\"font-weight: bold;\">15. června&nbsp;2024</span> v <span style=\"font-weight: bold;\">Relax Slapy RESORT</span>.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} style={{"paddingTop":null,"paddingBottom":null}} layout={"l12"}>
          
          <ColumnWrap className="column__flex --full --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37249/69e8bb1ee3fb4b6cb9153650755aedb7_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37249/69e8bb1ee3fb4b6cb9153650755aedb7_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37249/69e8bb1ee3fb4b6cb9153650755aedb7_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37249/69e8bb1ee3fb4b6cb9153650755aedb7_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37249/69e8bb1ee3fb4b6cb9153650755aedb7_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37249/ac8ef54d2e3547eb85c290e81844c347_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37249/ac8ef54d2e3547eb85c290e81844c347_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37249/ac8ef54d2e3547eb85c290e81844c347_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37249/ac8ef54d2e3547eb85c290e81844c347_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37249/ac8ef54d2e3547eb85c290e81844c347_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37249/faebec90de1e42019021cbfd73c1296c_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37249/faebec90de1e42019021cbfd73c1296c_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37249/faebec90de1e42019021cbfd73c1296c_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37249/faebec90de1e42019021cbfd73c1296c_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37249/faebec90de1e42019021cbfd73c1296c_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37249/7e183e4b134042d89ae4713d8b90107e_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37249/7e183e4b134042d89ae4713d8b90107e_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37249/7e183e4b134042d89ae4713d8b90107e_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37249/7e183e4b134042d89ae4713d8b90107e_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37249/7e183e4b134042d89ae4713d8b90107e_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37249/39b2bc19aa9e4afaad2cce4dc2054acc_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37249/39b2bc19aa9e4afaad2cce4dc2054acc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37249/39b2bc19aa9e4afaad2cce4dc2054acc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37249/39b2bc19aa9e4afaad2cce4dc2054acc_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37249/54c0f8c38487499fa66ded2aefa2929d_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37249/54c0f8c38487499fa66ded2aefa2929d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37249/54c0f8c38487499fa66ded2aefa2929d_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37249/54c0f8c38487499fa66ded2aefa2929d_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37249/54c0f8c38487499fa66ded2aefa2929d_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"program"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --full --left el--4 pl--40 pr--40" style={{"paddingLeft":null,"paddingRight":null}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"12:30 - 13:30&nbsp;"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Příjezd hostů,<br>ubytování, občerstvení<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"14:00"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Svatební obřad"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"14:45"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Společné focení"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"15:30"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Zahájení rautu, proslovy<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"17:00"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Krájení dortu, házení květiny"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"19:00"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"První novomanželský tanec<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"19:15"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Kapela&nbsp;Jazz in Deck"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"22:00<br>"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Party DJ<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"8cwyfo49kds"} style={{"backgroundColor":"#f2f2f2"}}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"font-weight: bold;\">Relax Slapy Resort </span><br>Hubenov 17, 263 01 Borotice, okres Příbram<br>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"8"} place={"Hubenov 17"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--60 pt--60" name={"ds0jz0yydoe"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1" content={"Další informace<br>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Průběh svatby<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Celá svatba, včetně obřadu, hostiny a party bude probíhat v areálu <a href=\"https://www.relaxslapy.cz/\">Relax Slapy RESORT</a>.&nbsp; Pokud nám to počasí dovolí, budeme celý den venku."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Doprava"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Na místo je možné se dopravit pouze autem. Míst k parkování je dostatek."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Ubytování"}>
              </Subtitle>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"Ubytování pro hosty je zajištěno taktéž v areálu, a to ve srubech s vlastním sociálním zařízením a kuchyňkou. Jen nám prosím dejte nejpozději do&nbsp;29. února 2024 vědět, jestli budete mít o ubytování zájem. Ubytování pro hosty včetně nedělní snídaně je na nás."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Dress code"}>
              </Subtitle>

              <Text className="text-box text-box--justify" content={"Na obřad a focení si prosím vezměte formálnější oděv, barvy neřešíme. V průběhu dne se můžete převléct do něčeho pohodlnějšího.&nbsp;<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Děti a zvířata"}>
              </Subtitle>

              <Text className="text-box text-box--justify" content={"Celou akci koncipujeme pro naše dospělé blízké a přátele. Proto budeme rádi, pokud si pro své ratolesti a mazlíčky zajistíte hlídání a akci si s námi užijete sami.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"polozky"} style={{"marginTop":0,"paddingTop":40,"paddingBottom":40,"backgroundColor":"var(--color-blend--95)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":0}} columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":0}}>
              
              <Text className="text-box ff--1 fs--48" style={{"maxWidth":450,"marginTop":0,"marginBottom":0}} content={"<span style=\"color: var(--color-supplementary);\">Svatební dary</span>"}>
              </Text>

              <Text className="text-box ff--1 fs--18" style={{"maxWidth":840,"marginTop":2,"marginBottom":0}} content={"<span style=\"caret-color: rgb(210, 5, 30); color: var(--black);\">Potěší nás jakýkoliv finanční příspěvek na naši svatební cestu. Hmotné dary, prosíme, nenoste.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"iv1rsvt7trj"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Potvrzení účasti<br>"}>
              </Title>

              <Subtitle className="subtitle-box fs--18 mt--16" content={"Věnujte nám prosím pár minut a vyplňte tento formulář nejpozději do 29. února 2024."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":740}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Jméno a příjmení","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Účast","type":"select","options":["Zúčastním se","Bohužel nemohu"],"required":true},{"name":"Doprovod","type":"select","options":["Dorazím s partnerem","Dorazím sám","Nedorazím"],"required":true},{"name":"Ubytování","type":"select","options":["Chci ubytování","Nechci ubytování","Nedorazím"],"required":true},{"name":"Poznámka / dotaz","type":"textarea"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}